import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark, faPercent, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import Modal from 'components/UI/Modal'
import { ModalButtonsDisplay, ModalSize } from 'components/UI/Modal/Modal'
import backgroundImage from '../../../../assets/images/goldenBg1.svg'
import StoresContext from 'providers/storesContext'
import Button from 'components/UI/Button'
import DiscountCodeRequestForm from './DiscountCodeRequestForm'
import { isEmptyObject } from '../../../../shared/utility'
import { TERMS_AND_CONDITIONS } from 'routing/Paths'
import { Tooltip } from '@mui/material'

type PhotographerDiscountCodeProps = {
  afterSaveDiscount: () => void
}

const PhotographerDiscountCode = (props: PhotographerDiscountCodeProps) => {
  const [visibleDiscountCodeModalRequestForm, setVisibleDiscountCodeModalRequestForm] =
    useState(false)
  const [visibleDiscountCodeInfoModal, setVisibleDiscountCodeInfoModal] = useState(false)
  const { authStore } = useContext(StoresContext)!

  const loggedUser = authStore.getLoggedUser()

  const { t } = useTranslation()

  const personalDiscountInfoModal = (
    <Modal
      displayCloseIcon={false}
      onCancel={() => setVisibleDiscountCodeInfoModal(false)}
      opened={visibleDiscountCodeInfoModal}
      buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
      modalSize={ModalSize.MEDIUM}
    >
      <div className="w-full">
        <div className="relative overflow-hidden h-14 rounded-t-md">
          <img src={backgroundImage} className="w-full" alt="background" />
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="absolute text-xl cursor-pointer top-2 right-2"
            onClick={() => setVisibleDiscountCodeInfoModal(false)}
          />
        </div>
        <div className="relative h-auto">
          <div className="absolute w-full text-center -top-6">
            <div className="flex flex-col justify-center gap-2">
              <h3 className="mb-0 text-center">
                <span className="py-1 px-2 text-2xl font-medium bg-white rounded-md shadow-md">
                  {t('Fidelity code')}
                </span>
              </h3>
              <span className="text-lumepic-grey text-lg">
                {t('We want to help you use Lumepic!')}
              </span>
            </div>
          </div>
        </div>
        <div className="w-full bg-bg_details rounded-b-md pt-10 pb-0.5">
          <div className="md:mx-2.5 mt-2.5 mb-3 p-3.5 rounded-md bg-white">
            <p className="text-sm md:text-base font-light">
              {t(
                'Here you can access a fidelity code to share with your customers. This code will be for single use and will have two effects:'
              )}
            </p>
            <ul className="mx-5 list-decimal">
              <li className="text-sm md:text-base font-light">
                {' '}
                {t(
                  'It will grant the user a % discount (photographer’s choice of amount) on the total value of that purchase'
                )}
                .
              </li>
              <li className="text-sm md:text-base font-light">
                {' '}
                {t(
                  'Lumepic will NOT CHARGE any COMMISSION for that purchase or for all purchases that the user makes to you for ONE YEAR since that purchase (the cost of Stripe will still be charged)'
                )}
                .
              </li>
            </ul>
            <p className="text-sm md:text-base font-light pt-2">
              {' '}
              {t(
                'We believe that in this way, Lumepic is fairer, as it doesn’t charge for the customers that the photographer already had in their customer base, and it will only charge for new customers that Lumepic generates. (It’s worth noting that each athlete user will be able to use only one of these codes)'
              )}
              .
            </p>
            <span className="w-full flex justify-center mt-3 text-sm md:text-base">
              {t('Click')}
              <a
                target="_blank"
                href={TERMS_AND_CONDITIONS}
                rel="noreferrer"
                className="text-lumepic-light_black underline hover:text-lumepic-black mx-1"
              >
                {t('here')}
              </a>{' '}
              {t('to access more information')}.
            </span>{' '}
          </div>
        </div>
      </div>
    </Modal>
  )

  return (
    <div className="w-full bg-white p-3 border-t-4 border-primary md:p-0 md:ml-4">
      <div className="p-3">
        <div className="flex-col">
          <div className="flex items-center gap-2 font-semibold text-gray-900 leading-8 mb-3">
            <i className="text-green-500">
              <FontAwesomeIcon icon={faPercent} />
            </i>
            <span className="tracking-wide text-center">{t('Fidelity code')}</span>
            <button onClick={() => setVisibleDiscountCodeInfoModal(true)}>
              <Tooltip title={t('How does it works?')} arrow>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="ml-1 text-lumepic-light_black opacity-85 cursor-pointer text-lg"
                />
              </Tooltip>
            </button>
            {personalDiscountInfoModal}
            {
              <DiscountCodeRequestForm
                open={visibleDiscountCodeModalRequestForm}
                handleClose={() => setVisibleDiscountCodeModalRequestForm(false)}
                afterSaveDiscount={props.afterSaveDiscount}
              />
            }
          </div>
        </div>

        {!isEmptyObject(loggedUser.personalCodeDiscount) ? (
          <>
            <div className="text-gray-700 flex-col justify-between md:items-center py-3">
              <span>
                {t('Discount percentage')}: {loggedUser.personalCodeDiscount?.amount} %
              </span>
            </div>
            <div className="text-gray-700 flex-col justify-between md:items-center py-3">
              <span>
                {t('Code')}: <strong>{loggedUser.personalCodeDiscount?.code}</strong>
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="mt-10">
              <Button
                btnType="PrimaryAction"
                onClick={() => setVisibleDiscountCodeModalRequestForm(true)}
              >
                <span>{t('Request Fidelity Code')}</span>
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default PhotographerDiscountCode
