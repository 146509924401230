import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from 'stores/AuthStore'
import DashboardService from 'services/DashboardService'
import PhotographerMetricsInterface from 'services/Interfaces/User/PhotographerMetrics.interface'
import SearchBarStore from 'components/UI/SearchBar/SearchBarStore'
import Album from 'shared/models/Album'
import { FeedOrderQueryParams } from 'services/FeedService'

class MyAccountDashboardStore {
  public photographerMetrics: PhotographerMetricsInterface | null
  public salesInsights: {
    year: number
    month: number
    salesCount: number
    salesAmountInUsd: number
  }[]
  public publishedAlbums: { year: number; month: number; albumCount: number }[]
  public independentAlbumSales: { salesAmountInUsd: number; salesCount: number }
  public eventAlbumSales: { salesAmountInUsd: number; salesCount: number }
  public albums: Album[]
  public albumsPerPage: number
  public albumCount: number
  public page: number
  public salesByActivity: { activity: string; salesAmountInUsd: number; salesCount: number }[]
  public options?: { value: number; label: string }[]
  public isLoadingMetrics: boolean
  public isLoadingAlbumsData: boolean
  public isLoadingSalesInsights: boolean
  public isLoadingPublishedAlbums: boolean
  public days?: { value: number; label: string }
  public earnings: { [currency: string]: number }
  public numberOfSales: number
  public dashboardService: DashboardService
  private readonly searchBarStore: SearchBarStore
  public error: any
  public currentSortOrder?: FeedOrderQueryParams

  constructor(
    private readonly authStore: AuthStore,
    searchBarStore: SearchBarStore,
    options?: { value: number; label: string }[]
  ) {
    this.options = options
    this.reset()
    makeAutoObservable(this)
    this.dashboardService = new DashboardService()
    this.searchBarStore = searchBarStore
  }

  reset() {
    this.photographerMetrics = null
    this.salesInsights = []
    this.publishedAlbums = []
    this.independentAlbumSales = { salesAmountInUsd: 0, salesCount: 0 }
    this.eventAlbumSales = { salesAmountInUsd: 0, salesCount: 0 }
    this.salesByActivity = []
    this.albums = []
    this.albumsPerPage = 9
    this.albumCount = 0
    this.page = 1
    this.isLoadingMetrics = false
    this.isLoadingAlbumsData = false
    this.isLoadingSalesInsights = false
    this.isLoadingPublishedAlbums = false
  }

  async getPhotographerMetrics() {
    runInAction(() => {
      this.startLoadingMetrics()
    })
    const token = this.authStore.getToken()
    try {
      const metrics = await this.dashboardService.fetchPhotographerMetrics(
        {
          dateTo: this.searchBarStore.dateRange[1],
          dateFrom: this.searchBarStore.dateRange[0],
          activityId: this.searchBarStore.activity?.id,
          locationId: this.searchBarStore.location?.id,
          eventId: this.searchBarStore.event?.id,
        },
        token
      )
      runInAction(() => {
        this.photographerMetrics = metrics
        this.stopLoadingMetrics()
      })
    } catch (e: any) {
      this.error = e
      this.stopLoadingMetrics()
    }
  }

  async getPhotographerSalesInsights() {
    runInAction(() => {
      this.startLoadingSalesInsights()
    })
    const token = this.authStore.getToken()
    try {
      const response = await this.dashboardService.fetchPhotographerSalesInsights(token)
      runInAction(() => {
        this.salesInsights = response
        this.stopLoadingSalesInsights()
      })
    } catch (e: any) {
      this.error = e
      this.stopLoadingSalesInsights()
    }
  }

  async getPhotographerPublishedAlbums() {
    runInAction(() => {
      this.startLoading()
    })
    const token = this.authStore.getToken()
    try {
      const response = await this.dashboardService.fetchPhotographerPublishedAlbums(token)
      runInAction(() => {
        this.publishedAlbums = response
        this.stopLoading()
      })
    } catch (e: any) {
      this.error = e
      this.stopLoading()
    }
  }

  async getPhotographerSalesByType() {
    runInAction(() => {
      this.startLoadingSalesInsights()
    })
    const token = this.authStore.getToken()
    try {
      const response = await this.dashboardService.fetchPhotographerSalesByType(token)
      runInAction(() => {
        this.independentAlbumSales = response.independentAlbumSales
        this.eventAlbumSales = response.eventAlbumSales
        this.stopLoadingSalesInsights()
      })
    } catch (e: any) {
      this.error = e
      this.stopLoadingSalesInsights()
    }
  }

  async getPhotographerSalesByActivity() {
    runInAction(() => {
      this.startLoadingSalesInsights()
    })
    const token = this.authStore.getToken()
    try {
      const response = await this.dashboardService.fetchPhotographerSalesByActivity(token)
      runInAction(() => {
        this.salesByActivity = response
        this.stopLoadingSalesInsights()
      })
    } catch (e: any) {
      this.error = e
      this.stopLoadingSalesInsights()
    }
  }

  async getPhotographerAlbums(sortOrder?: FeedOrderQueryParams) {
    runInAction(() => {
      this.startLoadingAlbumsData()
    })
    const token = this.authStore.getToken()
    const limit = this.albumsPerPage
    const offset = (this.page - 1) * this.albumsPerPage
    try {
      const response = await this.dashboardService.fetchPhotographerAlbumsInsights(token, {
        limit: limit,
        offset: offset,
        order: sortOrder || this.currentSortOrder,
      })
      runInAction(() => {
        this.albums = response.albums
        this.albumCount = response.count
        this.currentSortOrder = sortOrder || this.currentSortOrder
        this.stopLoadingAlbumsData()
      })
    } catch (e: any) {
      this.error = e
      this.stopLoadingAlbumsData()
    }
  }

  async setDays(days: { value: number; label: string }) {
    this.days = days
  }

  setPage(page: number) {
    this.page = page
    this.getPhotographerAlbums()
  }

  setAlbumsPerPage(itemsPerPage: number) {
    this.albumsPerPage = itemsPerPage
    this.page = 1
    this.getPhotographerAlbums()
  }

  handleChangePage(event: unknown, newPage: number) {
    this.setPage(newPage + 1)
    this.getPhotographerAlbums()
  }

  startLoading() {
    this.isLoadingPublishedAlbums = true
  }

  stopLoading() {
    this.isLoadingPublishedAlbums = false
  }

  startLoadingMetrics() {
    this.isLoadingMetrics = true
  }

  stopLoadingMetrics() {
    this.isLoadingMetrics = false
  }

  startLoadingAlbumsData() {
    this.isLoadingAlbumsData = true
  }

  stopLoadingAlbumsData() {
    this.isLoadingAlbumsData = false
  }

  startLoadingSalesInsights() {
    this.isLoadingSalesInsights = true
  }

  stopLoadingSalesInsights() {
    this.isLoadingSalesInsights = false
  }
}

export default MyAccountDashboardStore
